import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetData, apiUpdateData } from "../../services/api.service";
import { store } from "../store/store";
import {
  GET_PAYMENT_SCHEDULE,
  GET_PROJECT_PAYMENT_SCHEDULE,
  RECEIVE_PAYMENT,
  SPLIT_PAYMENT,
} from "src/constants/apiUrls";
import { showToaster } from "./toasterSlice";

const initialState = {
  paymentSchedule: [],
  originalPendingPayments: [],
  projectPaymentSchedule: {},
};

export const getPaymentSchedule = createAsyncThunk(
  "@payment/getPaymentSchedule",
  async (param='customer', thunkAPI) => {
    const url = `${GET_PAYMENT_SCHEDULE}`;
    try {
      const response = await apiGetData(url,{params:{userType: param}});
      return response.data?.response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getPaymentScheduleByProject = createAsyncThunk(
  "@payment/getPaymentScheduleByProject",
  async (req, thunkAPI) => {
    const url = `${GET_PROJECT_PAYMENT_SCHEDULE}/${req.id}`;
    try {
      const response = await apiGetData(url,{params:{userType: req.tab}});
      return response.data?.response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const receivePayment = createAsyncThunk(
  "@payment/receiveOriginalPayment",
  async ({ id, body }, thunkAPI) => {
    const url = `${RECEIVE_PAYMENT}/${id}`;
    try {
      const response = await apiUpdateData(url, body);
      if (response.status === 200) {
        const responseData = response.data?.response;
        if (response?.data?.statusCode === 200) {
          store.dispatch(showToaster({ type: "success", text: "Received Payment Successfully!" }));
        }
        return responseData;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const splitPayment = createAsyncThunk(
  "@payment/splitPayment",
  async ({ body }, thunkAPI) => {


    console.log("BODY",body);
    const url = SPLIT_PAYMENT;
    try {
      const response = await apiUpdateData(url, body);
      if (response.status === 200) {
        const resposeData = response.data?.response;
        if (response?.data?.statusCode === 200) {
          store.dispatch(showToaster({ type: "success", text: "Payment Updated Successfully!" }));
          setTimeout(() => {
            window.location.reload();
          }, 1000); 
        }
        return resposeData;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    changePendingPayments: (state, action) => {
      state.projectPaymentSchedule.payments.pending = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentSchedule.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getPaymentSchedule.fulfilled, (state, action) => {
      state.loading = "idle";
      state.paymentSchedule = action.payload;
    });
    builder.addCase(getPaymentSchedule.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
    builder.addCase(getPaymentScheduleByProject.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getPaymentScheduleByProject.fulfilled, (state, action) => {
      state.loading = "idle";
      state.projectPaymentSchedule = action.payload;
      state.originalPendingPayments = action.payload?.payments?.pending;
    });
    builder.addCase(getPaymentScheduleByProject.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
    builder.addCase(receivePayment.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(receivePayment.fulfilled, (state, action) => {
      state.loading = "idle";
      state.projectPaymentSchedule.payments.paid.push(action.payload);
      state.projectPaymentSchedule.payments.pending =
        state.projectPaymentSchedule.payments.pending.filter((p) => p._id !== action.payload._id);
      state.loading = action.payload;
    });
    builder.addCase(receivePayment.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
    builder.addCase(splitPayment.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(splitPayment.fulfilled, (state, action) => {
      state.loading = "idle";
      state.loading = action.payload;
    });
    builder.addCase(splitPayment.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
  },
});

// selectors
export const paymentScheduleSelector = (state) => state.payment?.paymentSchedule;
export const projectPaymentScheduleSelector = (state) => state.payment?.projectPaymentSchedule;
export const originalPendingPaymentsSelector = (state) => state.payment?.originalPendingPayments;
export const { clearPaymentState, changePendingPayments } = paymentSlice.actions;

export default paymentSlice.reducer;
